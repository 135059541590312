import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

//Topic:
// 0 === Kontaktanfrage
// 1 === Mitgliedsanfrage
// 2 === Sponsoring
// 3 === Turnieranmeldung

function DefaultForm({ topic, data }) {
    const [loading, setLoading] = useState(false);

    // Funktion, um zu prüfen, ob alle Checkboxen ausgewählt wurden
    const isRulesConfirmed = (values) => {
        return values.acceptPrivacy && values.acceptParticipation && values.confirmOver18;
    };

    const sendEmail = async (formData) => {
        setLoading(true);
        try {
            const dataToSend = { ...formData, topic };
            const response = await axios.post(`https://server.raketefreiburg.de/request`, dataToSend);
            console.log('Erfolgreich gesendet:', response.data);
            setLoading(false);
            toast.success(`Deine Anfrage war erfolgreich!`);
        } catch (error) {
            setLoading(false);
            console.error('Fehler beim Senden der E-Mail:', error);
            toast.error("Die Emailadresse ist ungültig. Bitte wiederhole die Anmeldung.");
        }
    };

    const baseSchema = yup.object().shape({
        firstname: yup.string().required('Vorname ist erforderlich'),
        lastname: yup.string().required('Nachname ist erforderlich'),
        email: yup.string().email('Ungültige E-Mail-Adresse').required('E-Mail ist erforderlich'),
    });

    const schema =
        topic === 1
            ? baseSchema.shape({
                team: yup.string().oneOf(["1", "2", "3", "4"], "Ungültige Mannschaft").required('Mannschaft ist erforderlich'),
                message: yup.string().required('Nachricht ist erforderlich'),
            })
            : topic === 3 ? baseSchema.shape({
                teamname: yup.string().required('Gebe einen Mannschaftsnamen ein'),
                acceptPrivacy: yup.boolean().oneOf([true], 'Datenschutzbestimmungen müssen akzeptiert werden').required(),
                acceptParticipation: yup.boolean().oneOf([true], 'Teilnahmebedingungen müssen akzeptiert werden').required(),
                confirmOver18: yup.boolean().oneOf([true], 'Alle Teilnehmer müssen über 18 Jahre alt sein').required(),
                birthday: yup.date().required('Gib dein Geburtsdatum ein'),
                street: yup.string().required('Straße fehlt'),
                houseNumber: yup.string().required('Hausnummer fehlt'),
                postalCode: yup.string().required('Gib eine Postleitzahl ein'),
                city: yup.string().required('Ort fehlt'),
                phoneNumber: yup.string().required('Gib eine Telefonnummer ein')
            }) : baseSchema.shape({
                message: yup.string().required('Nachricht ist erforderlich'),
            });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
                console.log("hallo")
                const confirmRules = isRulesConfirmed(values); // Überprüfen, ob alle Regeln bestätigt wurden
                const formData = { ...values, confirmRules };
                sendEmail(formData);
                resetForm();
            }}
            initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                message: '',
                company: "",
                team: "",
                teamname: "",
                birthday: '',
                street: '',
                houseNumber: '',
                postalCode: '',
                city: '',
                phoneNumber: '',
                acceptPrivacy: false,
                acceptParticipation: false,
                confirmOver18: false,
                tournamentId: data,
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors }) => {
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        {topic === 2 && (
                            <Row className="mb-3 mx-1">
                                <Form.Group controlId="validationFormik07">
                                    <Form.Label className='fw-semibold'>Firma</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company"
                                        value={values.company}
                                        onChange={handleChange}
                                        isValid={touched.company && !errors.company}
                                        isInvalid={touched.company && !!errors.company}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.company}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        )}

                        {topic === 3 && (
                            <Row className="mb-3 mx-1">
                                <Form.Group controlId="validationFormikTeam">
                                    <Form.Label className='fw-semibold'>Teamname</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="teamname"
                                        value={values.teamname}
                                        onChange={handleChange}
                                        isValid={touched.teamname && !errors.teamname}
                                        isInvalid={touched.teamname && !!errors.teamname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.team}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        )}
                        <Row className="mb-3 mx-1">
                            <Form.Group as={Col} md="6" controlId="validationFormik01">
                                <Form.Label className='fw-semibold'>Vorname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstname"
                                    value={values.firstname}
                                    onChange={handleChange}
                                    isValid={touched.firstname && !errors.firstname}
                                    isInvalid={touched.firstname && !!errors.firstname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstname}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik02">
                                <Form.Label className='fw-semibold'>Nachname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastname"
                                    value={values.lastname}
                                    onChange={handleChange}
                                    isValid={touched.lastname && !errors.lastname}
                                    isInvalid={touched.lastname && !!errors.lastname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastname}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        {topic === 3 && <>
                            <Row className="mb-3 mx-1">
                                <Form.Group as={Col} controlId="validationFormikBirthday">
                                    <Form.Label className='fw-semibold'>Geburtstag</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="birthday"
                                        value={values.birthday}
                                        onChange={handleChange}
                                        isValid={touched.birthday && !errors.birthday}
                                        isInvalid={touched.birthday && !!errors.birthday}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.birthday}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 mx-1">
                                <Form.Group as={Col} controlId="validationFormikStreet">
                                    <Form.Label className='fw-semibold'>Straße</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        value={values.street}
                                        onChange={handleChange}
                                        isValid={touched.street && !errors.street}
                                        isInvalid={touched.street && !!errors.street}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.street}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="2" controlId="validationFormikHouseNumber">
                                    <Form.Label className='fw-semibold'>Nr.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="houseNumber"
                                        value={values.houseNumber}
                                        onChange={handleChange}
                                        isValid={touched.houseNumber && !errors.houseNumber}
                                        isInvalid={touched.houseNumber && !!errors.houseNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.houseNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 mx-1">
                                <Form.Group as={Col} md="4" controlId="validationFormikPostalCode">
                                    <Form.Label className='fw-semibold'>Postleitzahl</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="postalCode"
                                        value={values.postalCode}
                                        onChange={handleChange}
                                        isValid={touched.postalCode && !errors.postalCode}
                                        isInvalid={touched.postalCode && !!errors.postalCode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Ungültige PLZ
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="8" controlId="validationFormikCity">
                                    <Form.Label className='fw-semibold'>Ort</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        isValid={touched.city && !errors.city}
                                        isInvalid={touched.city && !!errors.city}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 mx-1">
                                <Form.Group controlId="validationFormikPhoneNumber">
                                    <Form.Label className='fw-semibold'>Telefonnummer</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        isValid={touched.phoneNumber && !errors.phoneNumber}
                                        isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phoneNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </>}
                        <Row className="mb-3 mx-1">
                            <Form.Group controlId="validationFormikEmail">
                                <Form.Label className='fw-semibold'>E-Mail-Adresse</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        {topic === 3 && <>
                            <Row className="mb-3 mx-1">
                                <Form.Group controlId="validationFormikAcceptParticipation">
                                    <Form.Check
                                        type="checkbox"
                                        name="acceptParticipation"
                                        value={values.acceptParticipation}
                                        onChange={handleChange}
                                        className='fs-7'
                                        label="Ich bestätige die Richtigkeit meiner oben gemachten Angaben. Ich habe die Teilnahmebedingungen gelesen und stimme diesen zu."
                                        isInvalid={touched.acceptParticipation && !!errors.acceptParticipation}
                                        feedback={errors.acceptParticipation}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 mx-1">
                                <Form.Group controlId="validationFormikConfirmOver18">
                                    <Form.Check
                                        type="checkbox"
                                        name="confirmOver18"
                                        value={values.confirmOver18}
                                        className='fs-7'
                                        onChange={handleChange}
                                        label="Ich bestätige, dass alle Teilnehmer über 16 Jahre alt sind."
                                        isInvalid={touched.confirmOver18 && !!errors.confirmOver18}
                                        feedback={errors.confirmOver18}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 mx-1">
                                <Form.Group controlId="validationFormikAcceptPrivacy">
                                    <Form.Check
                                        type="checkbox"
                                        name="acceptPrivacy"
                                        value={values.acceptPrivacy}
                                        onChange={handleChange}
                                        className='fs-7'
                                        label={<span>Wenn du dich für ein Turnier anmeldest, verarbeiten wir deine Daten nur zur Abwicklung des Turniers und gemäß den geltenden Datenschutzgesetzen. Deine Daten werden sicher und vertraulich behandelt. Mehr dazu findest du in unserer <a href="https://www.raketefreiburg.de/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.</span>} isInvalid={touched.acceptPrivacy && !!errors.acceptPrivacy}
                                        feedback={errors.acceptPrivacy}
                                    />
                                </Form.Group>
                            </Row>
                        </>}
                        {topic === 1 && (
                            <Row className="mb-3 mx-1">
                                <Form.Group className="mb-3" controlId="validationFormik04">
                                    <Form.Label className='fw-semibold'>Mannschaft</Form.Label>
                                    <Form.Select
                                        name="team"
                                        value={values.team}
                                        onChange={handleChange}
                                        isValid={touched.team && !errors.team}
                                        isInvalid={touched.team && !!errors.team}
                                        placeholder="Wähle eine Mannschaft aus"
                                    >
                                        <option value="">Wähle eine Mannschaft aus</option>
                                        <option value="1">Erste</option>
                                        <option value="2">Zweite</option>
                                        <option value="3">Frauen</option>
                                        <option value="4">Mixed</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.team}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        )}

                        {topic !== 3 && (
                            <Row className="mb-3 mx-1">
                                <Form.Group controlId="validationFormik05">
                                    <Form.Label className='fw-semibold'>{topic === 1 ? "Über dich" : "Nachricht"}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        value={values.message}
                                        onChange={handleChange}
                                        isValid={touched.message && !errors.message}
                                        isInvalid={touched.message && !!errors.message}
                                        rows={3}
                                        placeholder="Schreibe hier deine Nachricht"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        )}

                        <Row className='py-3 mx-3'>
                            <Button type="submit" variant="danger" className='rakete-font text-uppercase shadow-sm'>
                                {loading ? 'Wird gesendet...' : topic === 1 ? "Mitkicken" : topic === 3 ? 'Jetzt Anmelden' : "Anfrage senden"}
                            </Button>
                        </Row>

                    </Form>
                )
            }}
        </Formik>
    );
}

export default DefaultForm;
